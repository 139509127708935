<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="navMenuItems" />
  </div>
</template>

<script>
// import navMenuItems from '@/navigation/horizontal'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'
import store from '@/store'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    // onMounted(async()=>{
    //    await store.dispatch('verticalMenu/updateRouteList')
    // })
    // const navMenuItems = computed(() => store.state.verticalMenu.newRoute)
    let navMenuItems = ref([]);
    onMounted(async()=>{
      //  await store.dispatch('verticalMenu/updateRouteList')
      //  navMenuItems.value = store.state.verticalMenu.newRoute

      store.dispatch('verticalMenu/getUserRoleMenu').then(async response => {
        if(response && response.length) {
          await store.dispatch('verticalMenu/updateRouteList')
          navMenuItems.value = store.state.verticalMenu.newRoute
        }
      })
    })
    console.log('horizontal文件navMenuItems======', navMenuItems)
    return {
      navMenuItems,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
